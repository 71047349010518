import React from 'react'
import {StaticImage as Img} from "gatsby-plugin-image"
import { Col, Container, Row } from 'react-bootstrap'
import CounterBlock from './Components/CounterBlock'
import About from './style'
 
export default function AboutSection({...rest}){
return(
<About  {...rest}>
  <Container>
    <Row className="align-items-center justify-content-center container_counter">
      <Col xs="12" className="col-xl-5 col-lg-5 col-md-7 col-xs-8">
        <About.Image mb="30px" mbLG="0">
          <img className="w-img-1" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642006039/helios/HOME%20HELIOSDG/soluciones_1_2x_smps5u.png" alt="about" layout="fullWidth" placeholder="blurred"/>
          <About.ImageInner>
            <img className="w-img-2" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642006039/helios/HOME%20HELIOSDG/soluciones_2_2x_zj7to5.png" alt="about" layout="fullWidth" placeholder="blurred"/>
          </About.ImageInner>
        </About.Image>
      </Col>
      <Col xs="12" className="col-xl-5 offset-xl-2 col-lg-6 offset-lg-1 col-md-9 col-sm-11">
        <About.Box>
          <About.ContentTextBlock className="text-center text-lg-start">
            <About.Subtitle as="h6" className="helios_subttl">Tech & Digital</About.Subtitle>
            <About.Title as="h2" mb="30px" className="helios_ttl">Soluciones</About.Title>
            <About.Text className="helios_txt">Contamos con diversas soluciones de desarrollo propio que cubren las  operaciones de las diversas áreas de las empresas en gobierno y privadas.
            </About.Text>
            {/* <About.Text>A powerful web design is the one where the user is encouraged<br className="d-none d-sm-block d-lg-none d-xxl-block"/> to stay on the site and not bounce to some other  simple tips
            <br className="d-none d-sm-block d-lg-none d-xxl-block"/> that will improve the bounce rate tremendously.
            </About.Text> */}
          </About.ContentTextBlock>
          <CounterBlock mt="50px"/>
        </About.Box>
      </Col>
      
    </Row>
  </Container>
</About>

)
}