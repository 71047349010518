import React from 'react'
import { StaticImage as Img } from "gatsby-plugin-image"
import MapArea from "./style"
export default function ContactMap() {
    return (
        <div className='container map_container'>
            <p>Nuestros lugares de trabajo en todo el mundo</p>
            <h2 className="typography__Heading-fvecw2-0 style__Title-sc-372oxm-1 hipacl gZXJhI text-center">Países</h2>
            
            <MapArea>
                <img className="w-100" src="https://res.cloudinary.com/dv10simqm/image/upload/v1642007954/helios/HOME%20HELIOSDG/mapa_home_2x_c1odvd.png" alt="map-image" layout="fullWidth" placeholder="" />
                {/* <MapArea.Card className="card-1">
        <p>4 G-ral. Traian Mosoiu Street, A Building, 2nd</p>
    </MapArea.Card>
    <MapArea.Card className="card-2">
        <p>Entrance, Flat 30, Cluj-Napoca, Romania</p>
    </MapArea.Card> */}
            </MapArea>
        </div>
    )
}