import React from 'react'
import Counter from './style'
 
export default function CounterBlock({...rest}){
return(
<Counter {...rest}>
    <Counter.Single>
        <Counter.Title as="h3"><span className="counter txt_black"><i className="fas fa-plus mr-3"></i>120</span></Counter.Title>
        <Counter.Text className="txt_black">Clientes</Counter.Text>
    </Counter.Single>
    <Counter.Single>
        <Counter.Title as="h3"><span className="counter txt_black">24</span></Counter.Title>
        <Counter.Text className="txt_black">Empresas</Counter.Text>
    </Counter.Single>
    <Counter.Single>
        <Counter.Title as="h3"><span className="counter txt_black"><i className="fas fa-arrow-up mr-3"></i>200</span></Counter.Title>
        <Counter.Text className="txt_black">Proyectos</Counter.Text>
    </Counter.Single>
</Counter>

)
}